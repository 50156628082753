import * as React from "react"
import { withPrefix } from 'gatsby';
import Logo from '../images/Logo.js';
import Layout from "../components/layout"

// styles
const pageStyles = {
  //minHeight: "100vh",
  maxWidth: "100vw",
  margin: 0,
  color: "#303338",
  padding: "10%",
  fontFamily: "open-sans, sans-serif, serif",
  // backgroundImage: `url(${withPrefix('./static/DSC01872-1.png')})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",

}

const containerStyles = {
}


// markup
const IndexPage = () => {
  return (
    <Layout>
      <main style={pageStyles}>
        <div >
          <Logo />
        </div>
        <div style={containerStyles}>
          <p>
          This is the homepage of Aksel Fristrup, an aspiring mathematician working in arithmetic geometry. Fristrup is probing the fields of non-commutative Iwasawa theory and higher class field theory.
          </p>
          <p>
          Here, you'll soon find my research and various expository notes.
          </p>
          <p>
          Feel free to send me an email at
          <br /> aksel "at" fristrup.co
          </p>
        </div>
      </main>
    </Layout>
  )
}

export default IndexPage
